<template class="app">
<notifications classes="vue-notification notification" position="bottom right"/>
<router-view>

</router-view>
</template>

<style >
.notification {
  margin: 10px !important;
  padding: 15px !important;
  font-size: 95% !important;
}
.btn-primary {
  background: #093759 !important;
  border: #0c4977 !important;
}
.btn-primary:hover {
  background: #125b94 !important;
  border: #0c4977 !important;
}
a {
  color:  #093759 !important;
}
a:hover {
  color: #156eb4 !important;
}
.modal-content {
  border-radius: 5px !important;
  border: 0 !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>