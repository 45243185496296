<template>
  <div class="row">
    <div class="col-12 col-lg-7 col-md-12 col-sm-12">
      <div class="block">
        <div class="header">
          Active agents
          <div class="header-link">
            <router-link to="/agents">
              <font-awesome-icon icon="fa-edit"></font-awesome-icon>
            </router-link>
          </div>
        </div>
        <div class="content" v-if="clientInfo.agents">
          <div v-for="agent in clientInfo.agents.filter(a => {return a.agent_status !== 'NO_ACTIVE_SUBSCRIPTION' })" :key="agent.uuid" >
            <div style="padding: 5px" v-if="agent.subscriptions_info">
              <div><b>{{ agent.name === '' ? 'No name' : agent.name }}</b></div>
              <div class="row" style="margin: 0">
              <div class="col-7" >
                <table  style="width: 100%">
                  <tr>
                    <td>Online status:</td>
                    <td style="text-align: right" v-html="getAgentOnlineStatus(agent.online_status)"></td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td style="text-align: right" v-html="getAgentStatus(agent.agent_status)"></td>
                  </tr>
                  <tr>
                    <td>Registered IP:</td>
                    <td style="text-align: right" v-html="getRegisteredIp(agent.ip_address)"></td>
                  </tr>
                </table>
              </div>
              <div v-if="agent.agent_usage_stat" class="col-5" style="background: #FAFAFA; padding: 5px; border-radius: 5px;">
                <div style="text-align: center; font-weight: bold">Interfaces statistic</div>
                  <table style="width: 100%; text-align: center">
                    <tr>
                      <td >
                        <div style="font-weight: bold; font-size: 115%">{{agent.agent_usage_stat.max_count_interfaces}}</div>
                        <div style="font-weight: bold; color: gray; font-size: 115%">No data</div>
                        <div style="font-size: 90%">Used<br><small>(By period)</small></div>
                      </td>

                      <td>
                        <div style="font-weight: bold; font-size: 115%">{{agent.subscriptions_info.reserved_interfaces}}</div>
                        <div style="font-size: 90%">Reserved<br><small>(By subscriptions)</small></div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div v-else class="col-5">
                  <div class="row">
                    <div class="col-12" style="background: #FAFAFA; padding: 5px; border-radius: 5px;">
                      <a href="https://wiki.wildcore.tools/en/install/" target="_blank">Agent install instruction</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
          </div>
          <div style="width: 100%; padding-top: 15px; text-align: center" v-if="clientInfo.agents.filter(a => {return a.agent_status !== 'NO_ACTIVE_SUBSCRIPTION' }).length === 0 && trialAllowed">
            <button class="btn btn-primary " title="Activate trial version" @click="activateTrial">
              <font-awesome-icon icon="fa-plus"> </font-awesome-icon> Activate TRIAL <br><small>Create your first agent</small>
            </button>
          </div>
          <div style="width: 100%;  padding-top: 15px; text-align: center" v-else-if="clientInfo.agents.filter(a => {return a.agent_status !== 'NO_ACTIVE_SUBSCRIPTION' }).length === 0 && !trialAllowed">
            <button class="btn btn-primary" title="Add agent" @click="$router.push('/agents')"> Go to agents<br><small>and activate agents</small>
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 col-md-12 col-sm-12">
      <div class="block">
        <div class="header">
          General information
          <div class="header-link">
            <router-link to="/settings">
              <font-awesome-icon icon="fa-edit"></font-awesome-icon>
            </router-link>
          </div>
        </div>
        <div class="content">
          <table style="width: 100%; font-size: 90%">
            <tr>
              <td>Name</td>
              <th>{{ clientInfo.name }}</th>
            </tr>
            <tr>
              <td>Email</td>
              <th>{{ clientInfo.email }}</th>
            </tr>
            <tr>
              <td>Company name</td>
              <th>{{ clientInfo.company_name === '' ? 'Not setted' : clientInfo.company_name }}</th>
            </tr>
          </table>
          <hr>
          <table style="width: 100%;">
            <tr>
              <td>Balance</td>
              <th :style="`font-size: 120%; font-weight: bold; ` + (clientInfo.balance > 0 ? 'color: darkgreen;' : 'color: darkred')  ">
                {{ clientInfo.balance }}$
              </th>
            </tr>
            <tr>
              <td>Count agents</td>
              <th>{{ clientInfo.agents.length }}</th>
            </tr>
            <tr v-if="clientInfo.discount != '0'">
              <td>Discount</td>
              <th>{{ clientInfo.discount }}%</th>
            </tr>
          </table>
        </div>
      </div>

    </div>
    <BModal v-model="modals.activateTrial" :hide-footer="true" :hide-header="true">
       <ActivateTrial @submitted="trialActivated" ></ActivateTrial>
    </BModal>
  </div>
</template>


<script>
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import funcs from "@/components/js/funcs";
import copy from "copy-to-clipboard";
import ActivateTrial from "@/components/forms/ActivateTrial.vue";
import CreatePayment from "@/components/forms/CreatePayment.vue";

export default {
  computed: {
    funcs() {
      return funcs
    }
  },
  emits: ["reloadInfo"],
  props: {
    clientInfo: {
      type: Object,
      dafault: null,
    },
    reloadInfo: {
      type: Function,
      required: true
    }

  },
  components: {CreatePayment, ActivateTrial, FontAwesomeIcon},
  data() {
    return {
      loading: true,
      error: null,
      trialAllowed: false,
      modals: {
        activateTrial: false,
      }
    }
  },
  async mounted() {
    this.trialAllowed = await this.isTrialAllowed()
  },
  methods: {
    getAgentOnlineStatus(status) {
      if (status === 'ONLINE') {
        return `<span style="color: darkgreen; font-weight: bold">Online</span>`
      }
      if (status === 'OFFLINE') {
        return `<span style="color: darkblue; font-weight: bold">Offline</span>`
      }
      return `<span style="color: gray; font-weight: bold">Unknown</span>`
    },
    async isTrialAllowed() {
      let trialAllowed = false
      await axios.get('/api/v1/client/private/subscription/is-trial-allowed').then(r => {
            trialAllowed = r.data.data
        })
      return trialAllowed
    },
    activateTrial() {
      console.log("Activate trial")
      this.modals.activateTrial = true
    },
    trialActivated() {
      this.modals.activateTrial = false
      this.reloadInfo()
    },
    getAgentStatus(status) {
      if (status === 'REGISTERED') {
        return `<span style="color: darkgreen; font-weight: bold">Registered</span>`
      }
      if (status === 'NO_ACTIVE_SUBSCRIPTION') {
        return `<span style="color: darkblue; font-weight: bold">Not active(No subscriptions)</span>`
      }
      return `<span style="color: gray; font-weight: bold">Unknown</span>`
    },
    getRegisteredIp(ip) {
      if (!ip) {
        return `<span style="color: gray; font-weight: bold">Not registered</span>`
      }
      return ip
    },
    copyToClipboard(text) {
      copy(text)
      this.$notify({
        text: 'Copied to clipboard',
        type: 'notify'
      })
    }
  },
}
</script>

<style scoped>


.block {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 0.8;
  border-radius: 3px;
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px;
}

.block .header {
  font-weight: bold;
  padding: 10px 10px 10px 15px;
  background-color: #FAFAFA;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 100%;
  margin-bottom: 5px;
}

.block .header .header-link {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 22px;
}

.block .content {
  padding: 10px;
  overflow: auto;
  font-size: 95%;
}

.btn-start-trial {
  border-radius: 3px;
  font-weight: bold;
  font-size: 28px;
  padding: 15px;
  margin: 10px;
  border: 1px solid #FAFAFA;
  background: #FAFAFA;
}

.btn-start-trial:hover {
  border-radius: 3px;
  font-weight: bold;
  font-size: 28px;
  padding: 15px;
  margin: 10px;
  border: 1px solid #AAAAAA;
  background: #D0D0D0;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
}
.confirm-modal-content > * + *{
  margin: 0.5rem 0;
}
.confirm-modal-content h1 {
  font-size: 1.375rem;
}
.confirm-modal-content button {
  margin: 0.25rem 0 0 auto;
  padding: 0 8px;
  border: 1px solid;
  border-radius: 0.5rem;
}
.dark .confirm-modal-content {
  background: #000;
}
</style>