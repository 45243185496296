<template>
  <form class="row" @submit.prevent >
    <div class="col-12">
      <div class="form-outline mb-4">
        <label class="form-label" for="email">Name</label>
        <input
            v-model="form.name"
            id="name"
            class="form-control"
            placeholder="Agent name"
        />
      </div>
    </div>
    <div class="col-12">
      <button class="btn btn-primary" :disabled="btnDisabled" style="width: 100%" @click="addAgent">
        <font-awesome-icon icon="fa-plus"></font-awesome-icon>
        Add agent
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import validation from "@/components/js/Validation";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: {FontAwesomeIcon},
  emits: ["submitted"],
  props: {
    clientInfo: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      form: {
        name: "",
      },
      client: null,
      btnDisabled: false,
    }
  },
  methods: {
    addAgent() {
      this.btnDisabled = true
      axios.post('/api/v1/client/private/agent', this.form).then(r => {
        this.$notify({
          title: "Agent success added!",
          text: "Now activate subscriptions for your agent",
          type: 'success',
          duration: 12000,
        });
        this.btnDisabled = false
        this.$emit('submitted', r.data.data)
      }).catch(e => {
        this.$notify({
          title: "API error",
          text: e.message,
          type: 'error',
        })
      }).finally(() => {
        this.btnDisabled = false
      })
    }
  },
  mounted() {
    this.client = this.clientInfo
    console.log(this.clientInfo)
  }
}
</script>

<style scoped>

</style>