<template>

  <div class="pa__container p-3">
  <div class="row">
    <div class="col-12">
      <h3 style="text-align: center">
        Change email confirmation
      </h3>
      <br>
    </div>
    <div v-if="!loading">
       <div v-if="data !== null">
         New email: <b>{{data.new_email}}</b>
         <div>
           Status:
          <span style="color: #232354; font-weight: bold" v-if="data.status === 'wait_confirmation'">Wait confirmation</span>
         <span style="color: darkgreen; font-weight: bold" v-if="data.status === 'updated'">Updated!</span>
           </div>
         <br>
         <b>Confirmation process</b>
         <div :style="'color: ' + (data.old_email_confirmed ? 'darkgreen' : 'gray')">
           OLD email: <font-awesome-icon style="font-size: 150%;" :icon="`fa-solid ` + (data.old_email_confirmed ? 'fa-check' : 'fa-times')" />
         </div>
         <div :style="'color: ' + (data.new_email_confirmed ? 'darkgreen' : 'gray')">
           NEW email: <font-awesome-icon style="font-size: 150%" :icon="`fa-solid ` + (data.new_email_confirmed ? 'fa-check' : 'fa-times')" />
         </div>
       </div>
      <div v-if="error !== null">
        <h4 style="text-align: center; color: darkred">
          Something went wrong!<br>
          <small>{{error.message}}</small>
        </h4>
      </div>
    </div>
  </div>
    </div>
</template>


<script>
  import axios from "axios";
  import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

  export default {
    components: {FontAwesomeIcon},
    data() {
      return {
        loading: true,
        data: null,
        error: null,
      }
    },
    methods: {
      async confirmChange(uid) {
        this.loading = true
        await axios.get('/api/v1/client/private/profile/change-email/confirm', {
          params: {
            uid: uid,
          }
        }).then(r => {
          this.data = r.data.data
        }).catch(e => {
            this.error = e
        })
        this.loading = false
      }
    },
    mounted() {
      let uuid = this.$route.params.uuid
      this.confirmChange(uuid)
    }
  }
</script>