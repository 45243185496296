<script setup>

</script>

<template>
  <form @submit.prevent v-if="client !== null">
    <div class="form-outline mb-4">
      <label class="form-label" for="password">Password</label>
      <input
          v-model="form.password"
          id="password"
          type="password"
          placeholder="************"
          class="form-control"
          :class="{ 'is-invalid' : errors.password !== ''}"
      />
      <div class="invalid-feedback">{{errors.password}}</div>
    </div>
    <div class="form-outline mb-4">
      <label class="form-label" for="newEmail">New email</label>
      <input
          v-model="form.new_email"
          id="newEmail"
          type="email"
          placeholder="my@example.com"
          class="form-control"
          :class="{ 'is-invalid' : errors.new_email !== ''}"
      />
      <div class="invalid-feedback">{{ errors.new_email }}</div>
    </div>

    <div class="row">
      <div class="col mt-3">
        <button @click="saveSettings" class="col btn btn-primary">
          Change email
        </button>
      </div>

    </div>


  </form>
</template>

<script>
import axios from "axios";
import validation from "@/components/js/Validation";

export default {
  emits: ["reloadInfo"],
  props: {
    clientInfo: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      client: null,
      form: {
        password: '',
        new_email: '',
      },
      errors: {
        password: '',
        new_email: '',
      }
    }
  },
  methods: {
    saveSettings() {
      this.errors = {
        old: '',
        new_1: '',
        new_2: '',
      }

      if (this.form.password.trim() === '') {
        this.errors.old = "Password is required"
        return;
      }
      if (this.form.new_email.trim() === '') {
        this.errors.new_email = "New email is required"
        return;
      }
      if (!validation.validEmail(this.form.new_email)) {
        this.errors.new_email = "Incorrect email address"
        return;
      }

      axios.post('/api/v1/client/private/profile/change-email/request', this.form).then(r => {
        this.$notify({
          title: "Request to change email successed!",
          text: "Please, confirm the change of email by clicking on the links in the received messages to the old and new mailboxes",
          type: 'success',
          duration: 12000,
        });
        this.form = {
          password: '',
          new_email: '',
        }
        this.errors = {
          password: '',
          new_email: '',
        }
      }).catch(e => {
          this.$notify({
            title: "API error",
            text: e.message,
            type: 'error',
          })
      })
    }
  },
  mounted() {
    this.client = this.clientInfo
    console.log(this.clientInfo)
  }
}
</script>

<style scoped>

</style>