<script setup>

</script>

<template>
  <form @submit.prevent  v-if="client !== null">
    <div class="form-outline mb-4">
      <label class="form-label" for="form3Example3">Name</label>
      <input
          v-model="client.name"
          id="form3Example3"
          class="form-control"
      />
    </div>
    <div class="form-outline mb-4">
      <label class="form-label" for="form3Example3">Company name</label>
      <input
          v-model="client.company_name"
          id="form3Example3"
          class="form-control"
      />
    </div>
    <div class="row">
      <div class="col mt-3">
        <button @click="saveSettings" class="col btn btn-primary">
          Save settings
        </button>
      </div>

    </div>


  </form>
</template>

<script>
  import axios from "axios";

  export default {
    emits: ["reloadInfo"],
    props: {
      clientInfo: {
        type: Object,
        default: null,
      }
    },
    data() {
      return {
        client: null,
        errors: {
           name: '',
           company_name: '',
        }
      }
    },
    methods: {
      saveSettings() {
        axios.put('/api/v1/client/private/profile', {
          name: this.client.name,
          company_name: this.client.company_name,
        }).then(r => {
          this.$notify({
            text: "Account settings success saved!",
            type: 'success',
            duration: 4000,
          });
        }).catch(e => {

        })
      }
    },
    mounted() {
      this.client = this.clientInfo
      console.log(this.clientInfo)
    }
  }
</script>

<style scoped>

</style>