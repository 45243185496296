import moment from "moment/moment";


export default {
    formatTime(time, onlyDate = false) {
        let date = new Date(time)
        if(onlyDate) {
            return   moment(date).format("Y-MM-DD")
        }
        return moment(date).format("Y-MM-DD HH:mm:ss")
    }
}

