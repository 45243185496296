import {createRouter, createWebHistory} from "vue-router";
import Authorization from "@/pages/Authorization.vue";
import error from "@/pages/404.vue";
import MainCabinet from "@/pages/Cabinet/Main.vue";
import Settings from "@/pages/Cabinet/Settings.vue";
import ChangeEmailConfirmation from "@/pages/Cabinet/ChangeEmailConfirmation.vue";
import AccountInfo from "@/pages/Cabinet/AccountInfo.vue";
import LoginForm from "@/components/forms/LoginForm.vue";
import ForgotPasswordRequest from "@/components/forms/ForgotPasswordRequest.vue";
import ForgotResetPassword from "@/components/forms/ForgotResetPassword.vue";
import RegisterForm from "@/components/forms/RegisterForm.vue";
import Agents from "@/pages/Cabinet/Agents.vue";
import EditAgent from "@/pages/Cabinet/EditAgent.vue";

const routes = [
    {
        path: '/',
        component: MainCabinet,
        meta: {title: 'Cabinet'},
        children: [
            {
                path: '',
                name: 'account_info',
                component: AccountInfo,
                meta: {title: 'Home'},
            },
            {
                path: 'agents',
                name: 'agents',
                component: Agents,
                meta: {title: 'Agents'},
            },
            {
                path: 'agents/:id',
                name: 'edit_agent',
                component: EditAgent,
                meta: {title: 'Edit agent'},
            },
            {
                path: 'settings',
                name: 'account_settings',
                component: Settings,
                meta: {title: 'Settings'},
            },
            {
                path: 'profile/change-email-confirmation/:uuid',
                name: 'change_email_confirmation',
                component: ChangeEmailConfirmation,
                meta: {title: 'Change email confirmation'},
            },
            {
                path: 'payments',
                name: 'payments',
                component: () => import('@/pages/Cabinet/Payments/Index'),
                meta: {title: 'Payments'},
                children: [
                    {
                        path: 'main',
                        name: 'payments.main',
                        component: () => import('@/pages/Cabinet/Payments/tabs/main'),
                        meta: {title: 'Main'},
                    },
                    {
                        path: 'debits',
                        name: 'payments.debits',
                        component: () => import('@/pages/Cabinet/Payments/tabs/debits'),
                        meta: {title: 'Debits'},
                    },
                    {
                        path: 'deposits',
                        name: 'payments.deposits',
                        component: () => import('@/pages/Cabinet/Payments/tabs/deposits'),
                        meta: {title: 'Deposits'},
                    },
                ]
            },
        ],
    },
    {
        path: '/auth',
        component: Authorization,
        meta: {title: 'Authorization', allowAnonymous: true},
        children: [
            {
                path: 'login',
                name: 'login',
                component: LoginForm,
                meta: {title: 'Login', allowAnonymous: true},
            },
            {
                path: 'forgot-password',
                name: 'forgot_password',
                component: ForgotPasswordRequest,
                meta: {title: 'Forgot password', allowAnonymous: true},
            },
            {
                path: 'reset-password/:uuid',
                name: 'reset_password',
                component: ForgotResetPassword,
                meta: {title: 'Reset password', allowAnonymous: true},
            },
            {
                path: 'registration',
                name: 'registration',
                component: RegisterForm,
                meta: {title: 'Registration', allowAnonymous: true},
            },
        ]
    },
    {
        path: '/:catchAll(.*)',
        component: error,
        meta: {title: '404', allowAnonymous: true,}
    },
]

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
})

router.beforeEach((to, from, next) => {
    const title = to.meta.title
    if (title) {
        document.title = title
    }
    if (to.matched.some(record => typeof record.meta.allowAnonymous === 'undefined' || !record.meta.allowAnonymous)) {
        let token = localStorage.getItem('AUTH_TOKEN')
        if (token && token !== '') {
            next()
            return
        }
        next('/auth/login?redirect=' + to.path)
    } else {
        next()
    }
})

export default router;
