<template>
  <div>
    <form  @submit.prevent >
        <div class="row mb-3">
          <label class="col-sm-4 col-form-label col-form-label-sm" for="email">Subscription name</label>
          <div class="col-sm-8">
          <select
              v-model="form.subscription"
              class="form-control "
          >
            <option v-for="option in options.subscriptions" :value="option" >{{option.name}}</option>
          </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12" style="font-size: 90%">
            <span style="font-weight: bold; color: darkgray" v-if="form.subscription && form.subscription.type !== 'AGENT'">Subscription expands functionality</span>

          </div>
        </div>
        <div class="row mb-3">
          <label class="col-sm-4 col-form-label col-form-label-sm" for="email">Pay period</label>
          <div class="col-sm-8">
          <select
              v-model="form.pay_period"
              class="form-control"
          >
            <option v-for="option in options.pay_period" :value="option.key" >{{option.value}}</option>
          </select>
            </div>
        </div>
        <div class="row mb-3" v-if="form.subscription && form.subscription.type === 'AGENT'">
          <label class="col-sm-4 col-form-label col-form-label-sm" for="email">Reserved interfaces</label>
          <div class="col-sm-8">
          <input type="number" minlength="1000" step="1000" class="form-control" v-model="form.reserved_interfaces">
            </div>
        </div>
        <div class="row mb-3">
          <label class="col-sm-4 col-form-label col-form-label-sm" for="email">Renew automatically</label>
          <div class="col-sm-8">
          <input type="checkbox" v-model="form.renew_automatically" style="width: 26px; height: 26px">
            </div>
        </div>
        <div class="row mb-3" v-if="chargeCalculated && !chargeCalculationUpdating">
          <div class="col-12">
            <hr>
                 Cost by {{form.pay_period}}: <b>{{chargeCalculated.charging_to_full_period.toFixed(2)}}$</b><br>
                 <br>
                 Cost for current period : <b>{{chargeCalculated.charging_to_expires.toFixed(2)}}$</b><br>
                 Will expires: <b>{{funcs.formatTime(chargeCalculated.expires_at, true)}}</b>
                <br>
                <br>
                <small>
                  <li>Month = 30 days</li>
                  <li>Year = 365 days</li>
                </small>
            <hr>
          </div>
        </div>
        <div class="row mb-3" v-if="chargeCalculationUpdating" >
          <div class="col-12" style="text-align: center; " >
            <hr>
            <BSpinner variant="secondary" style="align-content: center; text-align: center; margin: 62px" />
            <hr>
          </div>
        </div>
        <button class="btn btn-primary" :disabled="btnDisabled" style="width: 100%" @click="addSubscription">
          <font-awesome-icon icon="fa-plus"></font-awesome-icon>
          Add subscription
        </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import validation from "@/components/js/Validation";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Preloader from "@/components/UI/Preloader.vue";
import funcs from "../js/funcs";

export default {
  computed: {
    funcs() {
      return funcs
    }
  },
  components: {Preloader, FontAwesomeIcon},
  emits: ["submitted"],
  props: {
    agent: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      chargeCalculationUpdating: false,
      chargeCalculated: null,
      form: {
        renew_automatically: true,
        subscription: null,
        reserved_interfaces: 5000,
        pay_period: "MONTH",
      },
      options: {
        subscriptions: [],
        pay_period: [],
      },
      btnDisabled: false,
    }
  },
  watch: {
    form: {
      handler(n) {
        this.recalcCharge()
      },
      deep: true,
    }
  },
  methods: {
    recalcCharge() {
      if (!this.form.subscription) {
        return
      }
      if (this.form.reserved_interfaces === '' || this.form.reserved_interfaces < 1000) {
        this.form.reserved_interfaces = 1000
      }
      this.btnDisabled = true
      this.chargeCalculationUpdating = true
      axios.post('/api/v1/client/private/subscription/calculate-charging', this.form).then(r => {
        this.chargeCalculated = r.data.data
        this.chargeCalculationUpdating = false
      }).catch(e => {
        this.$notify({
          title: "API error",
          text: e.message,
          type: 'error',
        })
      }).finally(() => {
        this.chargeCalculationUpdating = false
        this.btnDisabled = false
      })
    },
    async loadSubscriptionList() {
      await axios.get('/api/v1/client/private/subscription' ).then(r => {
         let subscriptions = []
        console.log(this.agent.subscriptions_info.subscriptions)
         r.data.data.forEach(s => {
            if(this.agent.subscriptions_info && this.agent.subscriptions_info.subscriptions && this.agent.subscriptions_info.subscriptions.filter(ac => ac.id === s.id).length > 0) {
              console.log("Ignore subscription with id", s.id)
              return
            }
            if(this.form.subscription === null) {
              this.form.subscription = s
            }
            subscriptions.push(s)
         })
        this.options.subscriptions = subscriptions
      }).catch(e => {
        this.$notify({
          title: "API error",
          text: e.message,
          type: 'error',
        })
      }).finally(() => {
        this.btnDisabled = false
      })
    },
    addSubscription() {
      this.btnDisabled = true
      axios.post('/api/v1/client/private/subscription/' + this.agent.id, this.form).then(r => {
        this.$notify({
          title: "Subscription success added!",
          type: 'success',
          duration: 12000,
        });
        this.btnDisabled = false
        this.$emit('submitted', r.data.data)
      }).catch(e => {
        this.$notify({
          title: "API error",
          text: e.message,
          type: 'error',
        })
      }).finally(() => {
        this.btnDisabled = false
      })
    }
  },
  async mounted() {
    this.loading = true
    await this.loadSubscriptionList()
    let payPeriods = []
    for (const [key, value] of Object.entries(this.$enums.list('pay_period'))) {
      payPeriods.push({key: key, value: value})
    }
    this.options.pay_period = payPeriods
    this.loading = false
  }
}
</script>

<style scoped>

</style>