<script setup>

</script>

<template>
  <form @submit.prevent v-if="client !== null">
    <div class="form-outline mb-4">
      <label class="form-label" for="oldPassword">Old password</label>
      <input
          v-model="form.old"
          id="oldPassword"
          type="password"
          placeholder="************"
          class="form-control"
          :class="{ 'is-invalid' : errors.old !== ''}"
      />
      <div class="invalid-feedback">{{errors.old}}</div>
    </div>
    <div class="form-outline mb-4">
      <label class="form-label" for="newPassword1">New password</label>
      <input
          v-model="form.new_1"
          id="newPassword1"
          type="password"
          placeholder="************"
          class="form-control"
          :class="{ 'is-invalid' : errors.new_1 !== ''}"
      />
      <div class="invalid-feedback">{{ errors.new_1 }}</div>
    </div>
    <div class="form-outline mb-4">
      <label class="form-label" for="newPassword2">Repeat new password</label>
      <input
          v-model="form.new_2"
          id="newPassword2"
          type="password"
          placeholder="************"
          class="form-control"
          :class="{ 'is-invalid' : errors.new_2 !== '' }"
      />
      <div class="invalid-feedback">{{ errors.new_2 }}</div>

    </div>
    <div class="row">
      <div class="col mt-3">
        <button @click="saveSettings" class="col btn btn-primary">
          Change password
        </button>
      </div>

    </div>


  </form>
</template>

<script>
import axios from "axios";

export default {
  emits: ["reloadInfo"],
  props: {
    clientInfo: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      client: null,
      form: {
        old: '',
        new_1: '',
        new_2: '',
      },
      errors: {
        old: '',
        new_1: '',
        new_2: '',
      }
    }
  },
  methods: {
    saveSettings() {
      this.errors = {
        old: '',
        new_1: '',
        new_2: '',
      }

      if (this.form.old.trim() === '') {
        this.errors.old = "Field Old password is required"
        return;
      }

      if (this.form.new_1 !== this.form.new_2) {
        this.errors.new_1 = "Password doesn't match"
        this.errors.new_2 = "Password doesn't match"
        return
      }
      axios.put('/api/v1/client/private/profile/change-password', {
        old_password: this.form.old,
        new_password: this.form.new_1,
      }).then(r => {
        this.$notify({
          text: "Password success changed!",
          type: 'success',
          duration: 4000,
        });
        this.form = {
          old: '',
          new_1: '',
          new_2: '',
        }
        this.errors = {
          old: '',
          new_1: '',
          new_2: '',
        }
      }).catch(e => {
        if (e.type === 'OLD_PASSWORD_INCORRECT') {
          this.errors.old = e.message
        }
        if (e.type === 'PASSWORD_IS_LOW_SECURITY') {
          this.errors.new_2 = e.message
        }

      })
    }
  },
  mounted() {
    this.client = this.clientInfo
    console.log(this.clientInfo)
  }
}
</script>

<style scoped>

</style>