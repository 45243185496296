<script setup>

</script>

<template>
  <form @submit.prevent>
    <!-- Email input -->
    <div class="row" v-if="message !== ''">
      <div class="col-12" v-html="message" style="margin-bottom: 30px"></div>
    </div>
    <div class="form-outline mb-4">
      <label class="form-label" for="email">Email address</label>
      <input
          v-model="email"
          id="email"
          class="form-control"
          placeholder="Your email address"
          :class="{ 'is-invalid' : errors.email !== '' }"
      />
      <div class="invalid-feedback">{{ errors.email }}</div>
    </div>

    <!-- Password input -->
    <div class="form-outline mb-4">
      <label class="form-label" for="password">Password</label>
      <input
          v-model="password"
          type="password"
          placeholder="Your password"
          id="password"
          class="form-control"
          :class="{ 'is-invalid' : errors.password !== '' }"
      />
      <div class="invalid-feedback">{{ errors.password }}</div>
      <div style="float: right; font-size: 85%"><router-link to="/auth/forgot-password">Forgot password?</router-link></div>
    </div>
    <div class="row">
      <div class="col mt-3">
        <button @click="login" class="col btn btn-primary">
          Login
        </button>
      </div>
      <div class="col mt-3 text-start">
        <button @click="this.$router.push('/auth/Registration')" class="col btn btn-secondary">
          Registration
        </button>

      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label class="text-error" v-if="error" v-text="error"></label>
      </div>
      <div class="col text-end">
        <a class="login-footer__link" target="_blank" href="https://wiki.wildcore.tools/en/"
           title="Help Center">Help</a>
        <a class="login-footer__link" target="_blank" href="https://wildcore.tools/public-offer-agreement.pdf"
           title="Terms of service">Public offer agreement</a>
      </div>
    </div>


  </form>
</template>

<script>
import axios from "axios";
import validation from "@/components/js/Validation";

export default {
  data() {
    return {
      message: '',
      error: '',
      errors: {
        email: '',
        password: '',
      },
      email: '',
      password: '',
    }
  },
  methods: {
    async login() {
      if (!this.isFormValid()) {
        return
      }
        const newLogin = {
          email: this.email,
          password: this.password
        }

          await axios.post("/api/v1/client/auth/sign-in", newLogin).then(r => {
          console.log("THEN PROCESSED")
          console.log(r.data.data)
          localStorage.setItem('AUTH_TOKEN', r.data.data);
          this.$router.push('/')
        }).catch(e => {
          console.log("CATCH PROCESSED")
          console.log(e.response.data.error.description)
          console.log(e.response.status)
          this.error = 'Incorrect password or email'
        })
    },

    isFormValid() {
      let valid = true
      this.errors = {
        email: '',
        password: '',
      }
      if (this.email.trim() === '') {
        this.errors.email = 'email cannot be empty';
        valid = false
      } else if (!validation.validEmail(this.email)) {
        this.errors.email = 'email was incorrect';
        valid = false
      }

      if (this.password.trim() === '') {
        this.errors.password = 'password cannot be empty';
        valid = false
      }
      return valid;
    }
  },
  mounted() {
    let messageType = this.$route.query.message
    switch (messageType) {
      case 'email_success_confirm':
        this.message = `
            <div style="font-size: 120%; color: darkgreen">
            Email success confirmed!<br>
            <small>Please, login with you email and password</small>
</div>
        `
            break;
      case 'request_reset_password_success':
        this.message = `
            <div style="font-size: 120%; color: darkgreen">
            Request reset password successed!<br>
            <small>Please, check your email</small>
</div>
        `
            break;
      case 'password_success_changed':
        this.message = `
            <div style="font-size: 120%; color: darkgreen">
            Password success changed!<br>
            <small>Try to login with new password</small>
</div>
        `
            break;
    }
  }

}
</script>

<style scoped>
.btn {
  width: inherit;
}

.login-footer__link {
  color: #637381;
  font-size: 12px;
  text-decoration: none;
  margin-left: 1rem;
}

.error {
  border: red solid 2px;
}

.text-error {
  color: red;
  font-size: 12px;
}

.btn-secondary{
  background: transparent;
  border: #1D2B44FF solid 1px;
  color: #1D2B44FF;
}
</style>