<template>
  <div class="block" v-if="agent != null">
    <div class="header">
      <b>{{agent.name === '' ? 'No name' : agent.name}}</b>
      <div class="header-link">
        <router-link :to="'/agents/' + agent.id">
          <font-awesome-icon icon="fa-edit"></font-awesome-icon>
        </router-link>
      </div>
    </div>
    <div class="content" >
        <div style="padding: 5px">
          <div class="row" style="margin: 0">
            <div class="col-8 col-lg-7" >
              <table  style="width: 100%">
                <tr>
                  <td>Agent key:</td>
                  <td style="text-align: right" ><b>{{ agent.uuid }}</b> <a href="#" @click="copyToClipboard(agent.uuid)" style="font-size: 18px; padding: 5px"><font-awesome-icon icon="fa-copy" /></a></td>
                </tr>
                <tr>
                  <td>Status:</td>
                  <td style="text-align: right" v-html="getAgentStatus(agent.agent_status)"></td>
                </tr>
                <tr>
                  <td>Online status:</td>
                  <td style="text-align: right" v-html="getAgentOnlineStatus(agent.online_status)"></td>
                </tr>
                <tr>
                  <td>Registered IP:</td>
                  <td style="text-align: right" v-html="getRegisteredIp(agent.ip_address)"></td>
                </tr>
                <tr>
                  <td>Expires At:</td>
                  <td style="text-align: right" v-html="funcs.formatTime(agent.subscriptions_info.expired_at, true)"></td>
                </tr>
                <tr>
                  <td>Charged by subscriptions:</td>
                  <td style="text-align: right"  ><b>{{agent.subscriptions_info.charging_by_subscriptions}}$</b></td>
                </tr>
<!--                <tr>
                  <td style="vertical-align: top">Subscriptions:</td>
                  <td style="float: right" v-if="agent.subscriptions_info && agent.subscriptions_info.subcriptions.length !== 0">
                    <div style="text-align: left">
                      <li v-for="subscr in agent.subscriptions_info.subcriptions" :key="subscr.id">{{subscr.name}} </li>
                    </div>
                  </td>
                </tr>-->
              </table>
            </div>
            <div class="col-4 col-lg-5" v-if="agent.agent_usage_stat">
              <div class="row">
                <div class="col-12">
                  <div style="font-weight: bold">Interfaces statistic</div>
                </div>
                <div class="col-12" style="background: #F0F0F0; padding: 10px; border-radius: 3px">
                  <table style="width: 100%; ">
                    <tr>
                      <td>
                        <div style="font-size: 100%">Used<br><small>(By period)</small></div>
                      </td>
                      <td>
                        <div style="font-weight: bold; font-size: 115%">{{agent.agent_usage_stat.max_count_interfaces}}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <td>
                          <div style="font-size: 100%">Reserved<br><small>(By subscriptions)</small></div>
                        </td>
                      </td>
                      <td>
                        <div style="font-weight: bold; font-size: 115%">{{agent.subscriptions_info.reserved_interfaces}}</div>
                        <div style="font-size: 90%"></div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div v-else class="col-4 col-lg-5">
              <div class="row">
                <div class="col-12" style="background: #F0F0F0; padding: 10px; border-radius: 3px">
                  <a href="https://wiki.wildcore.tools/en/install/" target="_blank">Agent install instruction</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
 import copy from "copy-to-clipboard";
 import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
 import funcs from "@/components/js/funcs";
export default {
  components: {FontAwesomeIcon},
  computed: {
    funcs() {
      return funcs
    }
  },
   props: {
     agent: {
       type: Object,
       default: null,
     }
   },
   methods: {
     getAgentOnlineStatus(status) {
       if (status === 'ONLINE') {
         return `<span style="color: darkgreen; font-weight: bold">Online</span>`
       }
       if (status === 'OFFLINE') {
         return `<span style="color: darkblue; font-weight: bold">Offline</span>`
       }
       return `<span style="color: gray; font-weight: bold">Unknown</span>`
     },
     copyToClipboard(text) {
       copy(text)
       this.$notify({
         text: 'Copied to clipboard',
         type: 'notify'
       })
     },
     getAgentStatus(status) {
       if (status === 'REGISTERED') {
         return `<span style="color: darkgreen; font-weight: bold">Registered</span>`
       }
       if (status === 'NO_ACTIVE_SUBSCRIPTION') {
         return `<span style="color: darkblue; font-weight: bold">Not active(No subscriptions)</span>`
       }
       return `<span style="color: gray; font-weight: bold">Unknown</span>`
     },
     getRegisteredIp(ip) {
       if (!ip) {
         return `<span style="color: gray; font-weight: bold">Not registered</span>`
       }
       return ip
     },
   }
 }
</script>

<style scoped>

.block {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 0.8;
  border-radius: 3px;
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px;
}

.block .header {
  font-weight: bold;
  padding: 10px 10px 10px 15px;
  background-color: #FAFAFA;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 100%;
  margin-bottom: 5px;
}

.block .header .header-link {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 22px;
}

.block .content {
  padding: 10px;
  overflow: auto;
  font-size: 95%;
}
</style>