<script setup>

</script>

<template>
  <div>
    <div v-if="successRegistered" style="padding-top: 20px; padding-bottom: 55px">
      <h4 style="color: darkgreen; text-align: center">You success registered!<br><br>
        <small>Please, check you email for continue</small>
      </h4>
    </div>
  <form @submit.prevent v-else>
    <div class="form-outline mb-4">
      <label class="form-label" for="name">Name</label>
      <input
          v-model="name"
          id="name"
          class="form-control"
          placeholder="Your name"
          :class="{ 'is-invalid' : errors.name !== '' }"
      />
      <div class="invalid-feedback">{{ errors.name }}</div>
    </div>

    <div class="form-outline mb-4">
      <label class="form-label" for="name">Company name</label>
      <input
          v-model="company_name"
          id="name"
          class="form-control"
          placeholder="Your name"
          :class="{ 'is-invalid' : errors.company_name !== '' }"
      />
      <div class="invalid-feedback">{{ errors.company_name }}</div>
    </div>

    <!-- Email input -->
    <div class="form-outline mb-4">
      <label class="form-label" for="email">Email address</label>
      <input
          v-model="email"
          id="email"
          placeholder="Your email address"
          class="form-control"
          :class="{ 'is-invalid' : errors.email !== '' }"
      />
      <div class="invalid-feedback">{{ errors.email }}</div>
    </div>

    <!-- Password input -->
    <div class="form-outline mb-4">
      <label class="form-label" for="password">Password</label>
      <input
          v-model="password"
          type="password"
          id="password"
          placeholder="Password"
          class="form-control"
          :class="{ 'is-invalid' : errors.password !== '' }"
      />
      <div class="invalid-feedback">{{ errors.password }}</div>
    </div>
    <div class="form-outline mb-4">
      <label class="form-label" for="confirm_password">Confirm Password</label>
      <input
          v-bind:value="confirm_password"
          @input="confirm_password = $event.target.value"
          type="password"
          id="confirm_password"
          placeholder="Confirm password"
          class="form-control"
          :class="{ 'is-invalid' : errors.confirm_password !== '' }"
      />
      <div class="invalid-feedback">{{ errors.confirm_password }}</div>
    </div>
    <div class="form-outline mb-4">
      <label class="form-label" for="accept_agreement" style="float: right"><small>I accept the terms of the <a target="_blank" href="https://wildcore.tools/public-offer-agreement.pdf"
                                                                                                                title="Terms of service">public offer agreement</a></small></label>
      <input
          v-model="accept_agreement"
          type="checkbox"
          style="height: 26px; width: 26px"
          id="accept_agreement"
          :class="{ 'is-invalid' : errors.accept_agreement !== '' }"
      />
      <div class="invalid-feedback">{{ errors.accept_agreement }}</div>
    </div>
    <div class="row">
      <div class="col mt-3">
        <button @click="register" class="col btn btn-primary">
          Registration
        </button>
      </div>
      <div class="col mt-3" style="font-size: 85%">
        <router-link to="/auth/login" style="float: right">Go to login page</router-link>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col text-end">
        <a class="login-footer__link" target="_blank" href="https://wiki.wildcore.tools/en/"
           title="Help Center">Help</a>
        <a class="login-footer__link" target="_blank" href="https://wildcore.tools/public-offer-agreement.pdf"
           title="Terms of service">Public offer agreement</a>
      </div>
    </div>


  </form>
    </div>
</template>

<script>
import axios from "axios";
import router from "@/router/router";
import validation from "@/components/js/Validation";

export default {
  data() {
    return {
      errors: {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        accept_agreement: '',
        company_name: '',
      },
      email: '',
      accept_agreement: '',
      password: '',
      confirm_password: '',
      name: '',
      company_name: '',
      successRegistered: false,
    }
  },
  methods: {
    async register() {
      if (!this.isFormValid()) {
        return
      }
      const newRegister = {
        email: this.email,
        password: this.password,
        name: this.name,
        company_name: this.company_name,
      }

      const response = await axios.post("/api/v1/client/auth/sign-up", newRegister).then(r => {
        console.log("ok")
        this.successRegistered = true
      }).catch(e => {
        if (e.type === 'EMAIL_ALREADY_REGISTERED') {
          this.errors.email = e.message
          return
        }
        this.$notify({
          title: "API error",
          text: e.message,
          type: 'error',
          duration: 6000,
        })
      })
    },

    isFormValid() {
      let hasErrors = false
      this.errors = {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        accept_agreement: '',
        company_name: '',
      }

      if (!this.accept_agreement) {
        this.errors.accept_agreement = 'accept agreement is required'
        hasErrors = true
      }

      if (this.name.trim() === '') {
        this.errors.name = 'name cannot be empty';
        hasErrors = true
      }

      if (this.name.trim() === '') {
        this.errors.company_name = 'Company cannot be empty';
        hasErrors = true
      }

      if (this.email.trim() === '') {
        this.errors.email = 'email cannot be empty';
        hasErrors = true
      } else if (!validation.validEmail(this.email)) {
        this.errors.email = 'email is incorrect';
        hasErrors = true
      }

      if (this.password.trim() === '') {
        this.errors.password = 'password cannot be empty';
        hasErrors = true
      } else if (this.password.length < 8) {
        this.errors.password = 'password must contain 8 characters or more';
        hasErrors = true
      }

      if (this.password !== this.confirm_password) {
        this.errors.confirm_password = 'password mismatch';
        hasErrors = true
      }

      return !hasErrors;
    }
  }

}
</script>

<style scoped>
.btn {
  width: inherit;
}

.login-footer__link {
  color: #637381;
  font-size: 12px;
  text-decoration: none;
  margin-left: 1rem;
}

.error {
  border: red solid 2px;
}

.text-error {
  color: red;
  font-size: 12px;
}

</style>