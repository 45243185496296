<script setup>

</script>

<template>
  <section class="pa__login__block background-radial-gradient overflow-hidden">
    <img class="pa__image_404" src="../../public/images/404.png" alt="">
  </section>
</template>

<script >


export default({

});
</script>

<style scoped>
.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
  hsl(218, 41%, 35%) 15%,
  hsl(218, 41%, 30%) 35%,
  hsl(218, 41%, 20%) 75%,
  hsl(218, 41%, 19%) 80%,
  transparent 100%),
  radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%);
}


.pa__login__block {
  min-height: 100vh;
}
.pa__image_404{
  height: 100vh;
}
</style>