<template>
  <div>
    <Preloader v-if="loading"/>
    <div class="row"  v-else>
      <template v-if="agents.length !== 0">
        <div class="col-12 col-lg-12 col-md-12 col-sm-12" v-for="agent in  agents" :key="agent.id">
          <AgentBlock :agent="agent"></AgentBlock>
        </div>
      </template>
      <div v-else>
         <div class="block">
            <div class="header">Agents</div>
            <div class="content">
              <h4 style="text-align: center">Agents not found</h4>
            </div>
         </div>
      </div>
      <div style="width: 100%;  padding-top: 15px; text-align: center" v-if="!trialAllowed">
        <button class="btn btn-add-agent" title="Add agent" @click="addAgent" ><font-awesome-icon icon="fa-plus"></font-awesome-icon><br><small>Add agent</small>
        </button>
      </div>
      <div class="col-12 col-lg-12 col-md-12 col-sm-12" v-else>
        <div style="width: 100%;  padding-top: 15px; text-align: center" >
          <button class="btn btn-add-agent" title="Add agent" @click="activateTrial" style="width: 100%"><font-awesome-icon icon="fa-plus"></font-awesome-icon>Start trial</button>
        </div>
      </div>
    </div>

    <BModal v-model="modals.activateTrial" :hide-footer="true" :hide-header="true">
      <ActivateTrial @submitted="trialActivated"></ActivateTrial>
    </BModal>

    <BModal v-model="modals.addAgent"    :hide-footer="true"  title="Creating new agent">
      <AddAgent @submitted="agentAdded"></AddAgent>
    </BModal>
  </div>
</template>


<script>
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import funcs from "@/components/js/funcs";
import copy from "copy-to-clipboard";
import ActivateTrial from "@/components/forms/ActivateTrial.vue";
import AgentBlock from "@/components/UI/AgentBlock.vue";
import AddAgent from "@/components/forms/AddAgent.vue";
import Preloader from "@/components/UI/Preloader.vue";

export default {
  computed: {
    funcs() {
      return funcs
    }
  },
  emits: ["reloadInfo"],
  props: {
    clientInfo: {
      type: Object,
      dafault: null,
    },
    reloadInfo: {
      type: Function,
      required: true
    }
  },
  components: {Preloader, AddAgent, AgentBlock, ActivateTrial, FontAwesomeIcon},
  data() {
    return {
      loading: true,
      error: null,
      trialAllowed: false,
      agents: [],
      modals: {
        activateTrial: false,
        addAgent: false,
      }
    }
  },
  async mounted() {
    this.trialAllowed = await this.isTrialAllowed()
    this.agents = this.clientInfo.agents
    this.loading = false
  },
  methods: {
    async isTrialAllowed() {
      let trialAllowed = false
      await axios.get('/api/v1/client/private/subscription/is-trial-allowed').then(r => {
        trialAllowed = r.data.data
      })
      return trialAllowed
    },
    addAgent() {
      this.modals.addAgent = true
    },
    async agentAdded(agent) {
      this.modals.addAgent = false
      await this.reloadInfo()
      console.log("Go to agent")
      this.$router.push({name: "edit_agent", params: {id: agent.id}})
    },
    activateTrial() {
      console.log("Activate trial")
      this.modals.activateTrial = true
    },
    async trialActivated() {
      this.modals.activateTrial = false
      await this.reloadInfo()
      this.agents = this.clientInfo.agents
    },
    getAgentStatus(status) {
      if (status === 'REGISTERED') {
        return `<span style="color: darkgreen; font-weight: bold">Registered</span>`
      }
      if (status === 'NO_ACTIVE_SUBSCRIPTION') {
        return `<span style="color: darkblue; font-weight: bold">Not active(No subscriptions)</span>`
      }
      return `<span style="color: gray; font-weight: bold">Unknown</span>`
    },
    getRegisteredIp(ip) {
      if (!ip) {
        return `<span style="color: gray; font-weight: bold">Not registered</span>`
      }
      return ip
    },
    copyToClipboard(text) {
      copy(text)
      this.$notify({
        text: 'Copied to clipboard',
        type: 'notify'
      })
    }
  },
}
</script>

<style scoped>
.btn-add-agent {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 0.8;
  font-weight: bold;
  width: 100%;
  font-size: 110%;
}
.btn-add-agent:hover {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 0.95;
  font-weight: bold;
  width: 100%;
  font-size: 110%;
}

.block {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 0.8;
  border-radius: 3px;
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px;
}

.block .header {
  font-weight: bold;
  padding: 10px 10px 10px 15px;
  background-color: #FAFAFA;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 100%;
  margin-bottom: 5px;
}

.block .header .header-link {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 22px;
}

.block .content {
  padding: 5px;
  overflow: auto;
  font-size: 95%;
}

.btn-start-trial {
  border-radius: 3px;
  font-weight: bold;
  font-size: 28px;
  padding: 15px;
  margin: 10px;
  border: 1px solid #FAFAFA;
  background: #FAFAFA;
}

.btn-start-trial:hover {
  border-radius: 3px;
  font-weight: bold;
  font-size: 28px;
  padding: 15px;
  margin: 10px;
  border: 1px solid #AAAAAA;
  background: #D0D0D0;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
}

.confirm-modal-content > * + * {
  margin: 0.5rem 0;
}

.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.confirm-modal-content button {
  margin: 0.25rem 0 0 auto;
  padding: 0 8px;
  border: 1px solid;
  border-radius: 0.5rem;
}

.dark .confirm-modal-content {
  background: #000;
}
</style>