
<template>
  <div class="row">
    <div class="col-12" style="text-align: center">
      <h4>
        The TRIAL version can only be requested once.
        <br>
          After activation is provided for 14 days from the date of activation and interfaces limited for 2000 units(ONTs/ports)
      </h4>
      <h3>Do you want to activate the TRIAL now?</h3>
    </div>
    <div class="col-12">
      <button @click="activateTrial" class="btn btn-primary" :disabled="btnDisabled" style="width: 100%">
        Yes, activate TRIAL
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import validation from "@/components/js/Validation";

export default {
  emits: ["submitted"],
  props: {
    clientInfo: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      client: null,
      btnDisabled: false,
    }
  },
  methods: {
    activateTrial() {
      this.btnDisabled = true
      axios.put('/api/v1/client/private/subscription/start-trial', this.form).then(r => {
        this.$notify({
          title: "Subscription activated!",
          text: "You success activated TRIAL version. Please, read instructions for install WildcoreDMS on your system",
          type: 'success',
          duration: 12000,
        });
        this.btnDisabled = false
        this.$emit('submitted')
      }).catch(e => {
        this.$notify({
          title: "API error",
          text: e.message,
          type: 'error',
        })
      }).finally(() => {
        this.btnDisabled = false
      })
    }
  },
  mounted() {
    this.client = this.clientInfo
    console.log(this.clientInfo)
  }
}
</script>

<style scoped>

</style>