<template>
  <div>
    <div class="row" style="margin: 0; padding: 0 ">
      <div class="col-12 mb-3">
        <label>Deposit funds $</label>
        <input type="number" step="0.1" min="1" max="50000" class="form-control" placeholder="5.00">
      </div>
      <div class="col-12 mb-3">
        <button @click="depositFunds" class="btn btn-primary" :disabled="btnDisabled" style="width: 100%">
          Deposit funds
        </button>
      </div>
    </div>
    <BModal v-model="modals.checkout"     :hide-footer="true"  title="Deposit funds" id="checkout-modal">
    <div id="checkout">
      <div id="checkout_wrapper"></div>
    </div>
    </BModal>
  </div>
</template>

<script>
import axios from "axios";
import validation from "@/components/js/Validation";
import {loadScript} from "vue-plugin-load-script";

var slf = null

loadScript("https://pay.fondy.eu/static_common/v1/checkout/ipsp.js")
    .then(() => {
    })
    .catch(() => {
    });
loadScript("https://code.jquery.com/jquery-3.7.1.min.js")
    .then(() => {
    })
    .catch(() => {
    });
export default {
  emits: ["submitted"],
  props: {
    clientInfo: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      modals: {
        checkout: false,
      },
      client: null,
      btnDisabled: false,
    }
  },
  methods: {
    async getFondyConfiguration() {
      let config = null;
      await axios.get('/api/v1/client/private/portal/payment/fondy/configuration').then(r => {
        config = r.data.data
      })
      return config
    },
    async depositFunds() {
      this.btnDisabled = true
      let config = await this.getFondyConfiguration()
      var button = $ipsp.get('button');
      button.setMerchantId(config.merchant_id);
      button.setAmount(10, 'USD');
      button.setResponseUrl(config.response_url);
      button.setHost('pay.fondy.eu');
      button.addField({label: 'Account', name: 'order_desc', value: 'Account support@wildcore.tools', editable: false});
      this.btnDisabled = false
      this.checkoutInit(button.getUrl())
    },
    checkoutInit(url) {
      $ipsp('checkout').scope(function () {
        this.setCheckoutWrapper('#checkout_wrapper');
        this.addCallback(__DEFAULTCALLBACK__);
        this.action('show', function (data) {
          this.btnDisabled = true
          // $('#checkout_loader').remove();
          $('#checkout').show();
          slf.checkout = true
        });
        this.action('hide', function (data) {
          $('#checkout').hide();
          slf.checkout = false
          this.btnDisabled = false
        });
        this.action('resize', function (data) {
          $('#checkout_wrapper').height(data.height);
        });
        this.loadUrl(url);
      });
    },
  },
  mounted() {
    this.client = this.clientInfo
    slf = this.modals
  }
}
</script>

<style>
#checkout-modal-body  {
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding: 0 !important;
  overflow: auto;
}
</style>