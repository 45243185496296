<template>
  <div>
    <Preloader v-if="loading"></Preloader>
  <div v-if="agent !== null && !loading" class="row">
        <div class="col-7">
          <div class="block">
            <div class="header">
              <b>Agent info</b>
            </div>
            <div class="content">
              <div style="padding: 10px">
                <div class="row">
                  <div class="col-8">
                    <h4>{{agent.name}}
                      <a @click="changeName" href="#" id="edit-name"><font-awesome-icon icon="fa-edit"></font-awesome-icon></a>
                    </h4>
                  </div>
                  <div class="col-4" v-if="agent.online_status === 'UNKNOWN' && agent.agent_status === 'MUST_REGISTER'">
                    <a href="https://wiki.wildcore.tools/en/install/" target="_blank" style="font-weight: bold; color: darkblue; float: right">Install instruction</a>
                  </div>
                </div>
                <span style="float: left">Agent key:</span> <span style="float: right"><b>{{ agent.uuid }}</b> <a href="#" @click="copyToClipboard(agent.uuid)" style="font-size: 18px; padding: 5px"><font-awesome-icon icon="fa-copy" /></a></span>
                <table  style="width: 100%">
                  <tr>
                    <td>Status:</td>
                    <td style="text-align: right" v-html="getAgentStatus(agent.agent_status)"></td>
                  </tr>
                  <tr>
                    <td>Registered IP:</td>
                    <td style="text-align: right" v-if="!agent.ip_address" v-html="getRegisteredIp(agent.ip_address)"></td>
                    <td style="text-align: right" v-else>
                      <b>{{agent.ip_address}}</b>
                      <a id="reset-binding" href="#" @click="resetBinding" style="font-size: 20px; padding: 4px">
                        <font-awesome-icon icon="fa-link-slash"></font-awesome-icon>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Online status:</td>
                    <td style="text-align: right" v-html="getAgentOnlineStatus(agent.online_status)"></td>
                  </tr>
                  <tr v-if="agent.last_active !== null">
                    <td>Last active:</td>
                    <td style="text-align: right">{{funcs.formatTime(agent.last_active, false)}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="block">
            <div class="header">
              <b>Usage Stat</b>
            </div>
            <div class="content" >
              <div style="padding: 10px" v-if="agent.agent_status === 'REGISTERED' && agent.agent_usage_stat">
                  <table style="width: 100%">
                    <tr>
                      <td style="min-width: 160px">Version</td>
                      <td><b>{{agent.agent_usage_stat.version}}</b></td>
                    </tr>
                    <tr>
                      <td>Count interfaces<br><small>(current)</small></td>
                      <th style="vertical-align: top">{{agent.agent_usage_stat.count_interfaces}}</th>
                    </tr>
                    <tr>
                      <td>Count interfaces<br><small>(by period)</small></td>
                      <th style="vertical-align: top">{{agent.agent_usage_stat.max_count_interfaces}}</th>
                    </tr>
                    <tr>
                      <td> <small>Updated at</small></td>
                      <td style="vertical-align: top"><small>{{funcs.formatTime(agent.agent_usage_stat.updated_at, false)}}</small></td>
                    </tr>
                  </table>
              </div>
              <div v-else>
                    <h4 style="color: darkgray; text-align: center; padding-top: 15px; padding-bottom: 5px">No statistic</h4>
              </div>

            </div>
          </div>
        </div>
      <div class="col-12">
        <div class="block">
          <div class="header">
            <b>Subscriptions</b>
          </div>
          <div class="content" >
            <div class="row" style="margin: 0">
              <div class="col-8" v-if="agent.subscriptions_info">
                <h5>Summary</h5>
                <table style="width: 100%">
                  <tr>
                    <td>Expires at:</td>
                    <th>{{moment(subscription.expires_at).format("Y-MM-DD")}}</th>
                  </tr>
                  <tr>
                    <td>Charged by subscriptions:</td>
                    <th>{{subscription.last_charged}}$</th>
                  </tr>
                  <tr>
                    <td>Reserved interfaces:</td>
                    <th>{{subscription.reserved_interfaces}}</th>
                  </tr>
                </table>
              </div>
              <div class="col-4">
                <button class="btn btn-primary" @click="addSubscription" style="width: 100%"><font-awesome-icon icon="fa-plus"></font-awesome-icon> Add subscription </button>
              </div>
              <div class="col-12" v-if="subscription.list  && subscription.list.length > 0">
                <hr>
              </div>
              <div class="col-12" v-if="subscription.list && subscription.list.length > 0">
                <h5>Active subscriptions</h5>
                <div>
                <table class="table table-bordered table-responsive-sm table-sm table-responsive table-striped table-light">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Started At</th>
                        <th>Subscription</th>
                        <th>Pay period</th>
                        <th>Status</th>
                        <th>Reserved interfaces</th>
                        <th>Charged $</th>
                        <th>Expires At</th>
                        <th>Renew automatically</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="s in subscription.list.filter(s => ['ACTIVE', 'UNPAID', 'CREATED'].includes(s.status))" :key="s.id">
                        <td>{{s.id}}</td>
                        <td>{{funcs.formatTime(s.start_from, true)}}</td>
                        <td v-html="subscriptionCellDetail(s.subscription)"></td>
                        <td>{{$enums.v('pay_period', s.pay_period)}}</td>
                        <td>{{$enums.v('subscription_status', s.status)}}</td>
                        <td>{{s.subscription.type === 'AGENT' ? s.reserved_interfaces : 'N/A'}}</td>
                        <td>{{s.charged_amount}}</td>
                        <td>{{funcs.formatTime(s.expired_at, true)}}</td>
                        <td>
                          <input type="checkbox" :checked="s.renew_automatically" @change="renewAutomatically(this, s)" style="width: 24px; height: 24px">
                        </td>
                        <td>
                          <a href="#" @click="cancelSubscription(s)" v-if="['UNPAID', 'CREATED'].includes(s.status)">Cancel</a>
                        </td>
                      </tr>
                    </tbody>
                </table>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <BModal v-model="modals.changeName"  :lazy="true"   :hide-footer="true"  title="Change agent name">
      <ChangeAgentName :agent="agent" @submitted="nameChanged"></ChangeAgentName>
    </BModal>
    <BModal v-model="modals.addSubscription" :lazy="true"   :hide-footer="true"  title="Add subscription">
      <AddSubscription :agent="agent" @submitted="subscriptionAdded"></AddSubscription>
    </BModal>
    <BModal v-model="modals.resetBinding"  :lazy="true"   :hide-footer="true"  title="Reset agent binding">
      <ResetAgentBinding :agent="agent" @submitted="bindingReseted"></ResetAgentBinding>
    </BModal>
    <BPopover placement="top" triggers="hover" content="Reset bound IP address" target="reset-binding"></BPopover>
    <BPopover placement="top" triggers="hover" content="Edit agent name" target="edit-name"></BPopover>
  </div>
  </div>
</template>


<script>
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import funcs from "@/components/js/funcs";
import copy from "copy-to-clipboard";
import ActivateTrial from "@/components/forms/ActivateTrial.vue";
import AgentBlock from "@/components/UI/AgentBlock.vue";
import AddAgent from "@/components/forms/AddAgent.vue";
import ChangeAgentName from "@/components/forms/ChangeAgentName.vue";
import ResetAgentBinding from "@/components/forms/ResetAgentBinding.vue";
import Preloader from "@/components/UI/Preloader.vue";
import moment from "moment/moment";
import AddSubscription from "@/components/forms/AddSubscription.vue";
export default {
  computed: {
    funcs() {
      return funcs
    },
    moment() {
      return moment
    }
  },
  emits: ["reloadInfo"],
  props: {
    clientInfo: {
      type: Object,
      dafault: null,
    }
  },
  components: {AddSubscription, Preloader, ResetAgentBinding, ChangeAgentName, AddAgent, AgentBlock, ActivateTrial, FontAwesomeIcon},
  data() {
    return {
      loading: true,
      error: null,
      trialAllowed: false,
      agent: null,
      subscription: {
        list: [],
        expires_at: null,
        reserved_interfaces: 0,
        last_charged: 0,
      },
      modals: {
        changeName: false,
        resetBinding: false,
        addSubscription: false,
      }
    }
  },
  async mounted() {
    this.loading = true
    this.clientInfo.agents.forEach(a => {
      if(a.id == this.$route.params.id) {
        this.agent = a
      }
    })
    await this.loadSubscriptions(this.agent.id)
    this.loading = false
  },
  methods: {
    async renewAutomatically(checkbox, subscription) {
      checkbox.disabled = true
      await axios.put(`/api/v1/client/private/subscription/${this.agent.id}/${subscription.id}`, {
        "renew_automatically": !subscription.renew_automatically,
      }).then(r => {
        subscription.renew_automatically = !subscription.renew_automatically
        this.$notify({
          title: subscription.renew_automatically ? "Renew automatically enabled" : "Renew automatically disabled" ,
          text: "",
          type: 'success',
          duration: 12000,
        });
      }).catch(e => {
        this.$notify({
          title: "API error",
          text: e.message,
          type: 'error',
        })
      })
      return false
    },
    async cancelSubscription(subscription) {
      this.loading = true
      await axios.delete(`/api/v1/client/private/subscription/${this.agent.id}/${subscription.id}`).then(async r => {
        await this.loadSubscriptions(this.agent.id)
      }).finally(() => {
        this.loading = false
      })
    },
    async loadSubscriptions(agentId) {
      await axios.get('/api/v1/client/private/subscription/' + agentId).then(r => {
        this.subscription.list = r.data.data
      })
      let expires_at = new Date()
      let charged = 0.0
      let reserved_interfaces = 0
      this.subscription.list.forEach(s => {
        if(s.status !== 'ACTIVE') {
          return
        }
        charged += s.charged_amount
        if (s.subscription.type === "AGENT") {
          if(new Date(s.expired_at) > expires_at) {
            expires_at = new Date(s.expired_at)
          }
          reserved_interfaces += s.reserved_interfaces
        }
      })
      this.subscription.reserved_interfaces = reserved_interfaces
      this.subscription.last_charged = charged
      this.subscription.expires_at = expires_at
    },
    changeName() {
      this.modals.changeName = true
    },
    nameChanged(newName) {

    },
    addSubscription() {
      this.modals.addSubscription = true
    },
    async subscriptionAdded(subscription) {
      this.loading = true
      this.modals.addSubscription = false
      this.clientInfo.agents.forEach(a => {
        if(a.id == this.$route.params.id) {
          this.agent = a
        }
      })
      await this.loadSubscriptions(this.agent.id)
      this.loading = false
    },
    bindingReseted() {

      this.reloadInfo()
      this.agent.ip_address = ''
      this.agent.agent_status = 'MUST_REGISTER'
      this.modals.resetBinding = false
    },
    resetBinding() {
      this.modals.resetBinding = true
    },
    getAgentStatus(status) {
      if (status === 'REGISTERED') {
        return `<span style="color: darkgreen; font-weight: bold">Registered</span>`
      }
      if (status === 'NO_ACTIVE_SUBSCRIPTION') {
        return `<span style="color: darkblue; font-weight: bold">Not active(No subscriptions)</span>`
      }
      return `<span style="color: gray; font-weight: bold">Unknown</span>`
    },
    getAgentOnlineStatus(status) {
      if (status === 'ONLINE') {
        return `<span style="color: darkgreen; font-weight: bold">Online</span>`
      }
      if (status === 'OFFLINE') {
        return `<span style="color: darkblue; font-weight: bold">Offline</span>`
      }
      return `<span style="color: gray; font-weight: bold">Unknown</span>`
    },
    getRegisteredIp(ip) {
      if (!ip) {
        return `<span style="color: gray; font-weight: bold">Not registered</span>`
      }
      return ip
    },
    subscriptionCellDetail(subscription) {
      return subscription.name
    },
    copyToClipboard(text) {
      copy(text)
      this.$notify({
        text: 'Copied to clipboard',
        type: 'notify'
      })
    }
  },
}
</script>

<style scoped>

.block {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 0.8;
  border-radius: 3px;
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px;
}

.block .header {
  font-weight: bold;
  padding: 10px 10px 10px 15px;
  background-color: #FAFAFA;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 100%;
  margin-bottom: 5px;
}

.block .header .header-link {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 22px;
}

.block .content {
  padding: 5px;
  overflow: auto;
  font-size: 95%;
}

.btn-start-trial {
  border-radius: 3px;
  font-weight: bold;
  font-size: 28px;
  padding: 15px;
  margin: 10px;
  border: 1px solid #FAFAFA;
  background: #FAFAFA;
}

.btn-start-trial:hover {
  border-radius: 3px;
  font-weight: bold;
  font-size: 28px;
  padding: 15px;
  margin: 10px;
  border: 1px solid #AAAAAA;
  background: #D0D0D0;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
}

.confirm-modal-content > * + * {
  margin: 0.5rem 0;
}

.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.confirm-modal-content button {
  margin: 0.25rem 0 0 auto;
  padding: 0 8px;
  border: 1px solid;
  border-radius: 0.5rem;
}

.dark .confirm-modal-content {
  background: #000;
}
</style>