<template>
  <section class="pa__login__block background-radial-gradient overflow-hidden" style="min-width: 640px !important;">
    <div class="pa__shapes">
      <div id="radius-shape-1" class="position-absolute rounded-circle shadow-5-strong">
      </div>
      <div id="radius-shape-2" class="position-absolute shadow-5-strong">
      </div>
    </div>
    <!-- Navbar -->
    <nav class="navbar navbar-expand navbar-light bg-light pa__navbar">
      <!-- Container wrapper -->
      <div class="container-fluid" v-if="!loading">
        <!-- Toggle button -->
        <button
            class="navbar-toggler"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
          <i class="fas fa-bars"></i>
        </button>

        <!-- Collapsible wrapper -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- Navbar brand -->
          <a class="" href="#" style="margin-right: 10px;">
            <img
                src="https://wiki.wildcore.tools/en/assets/wildcore_logo.png"
                height="45"
                alt="MDB Logo"
                loading="lazy"
            />
          </a>

          <h4 class="pa__nav_header fw-bold">Wildcore DMS</h4>
          <!-- Left links -->
          <ul class="navbar-nav me-auto">
            <!-- <li class="nav-item">
              <a class="nav-link" href="#" @click="this.$router.push('/settings')">Dashboard</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Team</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Projects</a>
            </li>
            -->
          </ul>
          <!-- Left links -->
        </div>
        <!-- Collapsible wrapper -->

        <!-- Right elements -->
        <div class="d-flex align-items-center">
          <!-- Icon -->
          <div class="text-reset me-3">
            <i class="fas fa-shopping-cart"></i>
            <b>{{ clientInfo.name }}</b>
            <div style="font-size: 80%; color: gray">{{ clientInfo.email }}</div>
          </div>
          <button class="btn btn-outline-dark" title="Logout" @click="logout" style="padding: 5px; padding-left: 10px; padding-right: 10px; padding-top: 7px"><font-awesome-icon icon="fa-right-from-bracket"></font-awesome-icon></button>
        </div>
      </div>
      <!-- Container wrapper -->
    </nav>
    <!-- Navbar -->
    <div class="row">
      <div class="col-12 col-lg-3 col-xl-2 col-md-3 col-sm-3">
        <div class="pa__bar_left">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <router-link class="nav-link pa__nav-link"
                         :class="{'pa__active': tabAct === 'account_info'}"
                         :to="{name: 'account_info'}"
                         role="tab"
                         aria-controls="v-pills-home"
                         aria-selected="true">Home
            </router-link>
            <router-link class="nav-link pa__nav-link"
                         :class="{'pa__active': tabAct === 'agents'}"
                         :to="{name: 'agents'}"
                         role="tab"
                         aria-controls="v-pills-agents"
                         aria-selected="true">Agents
            </router-link>
            <router-link class="nav-link pa__nav-link"
                         :class="{'pa__active': tabAct.indexOf('payments') > -1}"
                         :to="{name: 'payments.main'}"
                         role="tab"
                         aria-controls="v-pills-agents"
                         aria-selected="true">Payments
            </router-link>



            <router-link class="nav-link pa__nav-link"
                         :class="{'pa__active': tabAct === 'account_settings'}"
                         :to="{name: 'account_settings'}"
                         role="tab"
                         aria-controls="v-pills-settings"
                         aria-selected="false">Settings
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-9 col-lg-9 col-xl-10 col-md-9">
          <router-view :client-info="clientInfo" :reload-info="getClientInfo" v-if="clientInfo !== null"/>
      </div>
    </div>

    <!-- Footer -->
    <footer class="text-center text-lg-start bg-light text-muted pa__footer ">
      <!-- Section: Social media -->
      <section class="d-flex justify-content-center justify-content-lg-between p-3 border-bottom" style="background: #FAFAFA; border-top-left-radius: 5px; border-top-right-radius: 5px">
        <!-- Left -->
        <div class="me-5 d-none d-lg-block">
          <span>Get connected with us on social network:</span>
        </div>
        <!-- Left -->

        <!-- Right -->
        <div>
          <a target="_blank" href="https://t.me/wildcore_dms_channel" class="me-4 text-reset text-decoration-none">
            Telegram Channel
          </a>

        </div>
        <!-- Right -->
      </section>
      <!-- Section: Social media -->

      <!-- Section: Links  -->
      <section class="">
        <div class="container text-center text-md-start mt-5">
          <!-- Grid row -->
          <div class="row mt-3">
            <!-- Grid column -->
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <!-- Content -->
              <h6 class="text-uppercase fw-bold mb-4">
                Wildcore DMS
              </h6>
              <p>
                Monitor all your devices from one place
                Access your equipment anywhere, monitor and manage wherever you are
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <!-- Links -->

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4">
                Useful links
              </h6>
              <p>
                <a href="https://wiki.wildcore.tools/"  target="_blank" class="text-reset">Documentation</a>
              </p>
              <p>
                <a href="https://github.com/wildcore-dms/issues/issues"  target="_blank" class="text-reset">Issues</a>
              </p>
              <p>
                <a href="https://t.me/wildcore_dms"  target="_blank" class="text-reset">News</a>
              </p>
              <p>
                <a href="https://wildcore.tools/" target="_blank" class="text-reset">Official</a>
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
              <p>   Ukraine, Kyiv</p>
              <p>
                <a href="mailto:support@wildcore.tools" class="text-reset">support@wildcore.tools</a>
              </p>
              <p>
                <a href="https://t.me/WildcoreSupport" class="text-reset">@WildcoreSupport</a>
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
        </div>
      </section>
      <!-- Section: Links  -->

      <!-- Copyright -->
      <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.05);">
        © 2023 Copyright:
        <a class="text-reset fw-bold" href="https://wildcore.tools/">wildcore.tools</a>
      </div>
      <!-- Copyright -->
    </footer>
    <!-- Footer -->

  </section>
</template>

<script>
import router from "@/router/router";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: {FontAwesomeIcon},
  data() {
    return {
      tabAct: 'info',
      clientInfo: null,
      loading: true,
    }
  },
  methods: {
    logout() {
      localStorage.setItem('AUTH_TOKEN', '')
      this.$router.push('/auth/login')
    },
    async getClientInfo() {
      this.loading = true
      await axios.get('/api/v1/client/private/profile').then(r => {
        this.clientInfo = r.data.data
      })
      console.log("Info reloaded")
      this.loading = false
    },
  },
  mounted() {
    if (this.clientInfo === null) {
      this.getClientInfo()
    }
    this.tabAct = this.$route.name
  },
  watch: {
    '$route': {
      handler(r) {
        this.tabAct = r.name
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
  hsl(218, 54%, 58%) 15%,
  hsl(218, 41%, 30%) 35%,
  hsl(218, 41%, 20%) 75%,
  hsl(218, 41%, 19%) 80%,
  transparent 100%),
  radial-gradient(1250px circle at 100% 100%,
      hsl(218, 46%, 53%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%);
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#d25a0c, #fcd5bd);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -1000px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#f86c0d, #fadbc1);
  overflow: hidden;
}

.pa__login__block {
  min-height: 100vh;
  padding-top: 50px;
  padding-left: 150px;
  padding-right: 150px;
}

@media only screen and (max-width: 1124px) {
  .pa__login__block {
    min-height: 100vh;
    padding: 20px;
  }
}

.pa__shapes {
  position: relative;
}

.pa__navbar {
  --bs-bg-opacity: 0.8;
  border-radius: 3px;
}

.pa__footer {
  --bs-bg-opacity: 0.8;
  border-radius: 3px;
  margin-top: 20px;
  position: relative;
}

.pa__nav_header {
  margin-bottom: 0;
  margin-right: 20px;
}

.pa__bar_left {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 0.8;
  border-radius: 3px;
  position: relative;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pa__nav-link {
  background: transparent;
  border: 0;
  border-radius: 0 !important;
  color: #3e444b;
  cursor: pointer;
  padding: 10px 5px 10px 15px;
}

.pa__nav-link.pa__active {
  border: 0;
  background: #FAFAFA;
  color: #000000;
  padding: 10px 5px 10px 10px;
  border-left: #093759 5px solid;
}

.pa__nav-link.pa__active:hover {
  border: 0;
  background: #FAFAFA;
  color: #000000;
  padding: 10px 5px 10px 10px;
  border-left: #093759 5px solid;
}

.pa__nav-link:hover {
  border: 0;
  background: #FAFAFA;
  padding: 10px 5px 10px 15px;
  color: #000000;
}

.pa__container {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 0.8;
  border-radius: 3px;
  position: relative;
  min-height: 300px;
  margin-top: 20px;
}
.my-modal {
  margin-top: 50px;
  max-width: 800px;
  width: 70%;
}
</style>