<script setup>

</script>

<template>
  <form @submit.prevent>
    <!-- Password input -->
    <div class="form-outline mb-4">
      <label class="form-label" for="password">Password</label>
      <input
          v-model="password"
          type="password"
          placeholder="Your password"
          id="password"
          class="form-control"
          :class="{ 'is-invalid' : errors.password !== '' }"
      />
      <div class="invalid-feedback">{{ errors.password }}</div>
    </div>
    <div class="form-outline mb-4">
      <label class="form-label" for="confirm-password">Confirm password</label>
      <input
          v-model="confirm_password"
          type="password"
          placeholder="Your password"
          id="confirm-password"
          class="form-control"
          :class="{ 'is-invalid' : errors.confirm_password !== '' }"
      />
      <div class="invalid-feedback">{{ errors.confirm_password }}</div>
    </div>
    <div class="row">
      <div class="col mt-3">
        <button @click="resetPassword" class="col btn btn-primary">
          Reset password
        </button>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label class="text-error" v-if="error" v-text="error"></label>
      </div>
      <div class="col text-end">
        <a class="login-footer__link" target="_blank" href="https://wiki.wildcore.tools/en/"
           title="Help Center">Help</a>
        <a class="login-footer__link" target="_blank" href="https://wildcore.tools/public-offer-agreement.pdf"
           title="Terms of service">Public offer agreement</a>
      </div>
    </div>


  </form>
</template>

<script>
import axios from "axios";
import validation from "@/components/js/Validation";

export default {
  data() {
    return {
      message: '',
      error: '',
      errors: {
        confirm_password: '',
        password: '',
      },
      confirm_password: '',
      password: '',
      uuid: '',
    }
  },
  methods: {
    async resetPassword() {
      if (!this.isFormValid()) {
        return
      }
      await axios.post(`/api/v1/client/auth/reset-password/${this.uuid}`, {
        password: this.password
      }).then(r => {
        this.$router.push('/auth/login?message=password_success_changed')
      }).catch(e => {
        console.log("CATCH PROCESSED")
        console.log(e.response.data.error.description)
        console.log(e.response.status)
        this.error = e.message
      })
    },

    isFormValid() {
      let valid = true
      this.errors = {
        password: '',
        confirm_password: '',
      }
      if (this.password.trim() === '') {
        this.errors.password = 'password cannot be empty';
        valid = false
      }
      if (this.confirm_password.trim() === '') {
        this.errors.confirm_password = 'password cannot be empty';
        valid = false
      }
      if (this.confirm_password.trim() !== this.password) {
        this.errors.confirm_password = 'password mismatch';
        this.errors.password = '     ';
        valid = false
      }
      return valid;
    }
  },
  mounted() {
      this.uuid = this.$route.params.uuid
  }

}
</script>

<style scoped>
.btn {
  width: inherit;
}

.login-footer__link {
  color: #637381;
  font-size: 12px;
  text-decoration: none;
  margin-left: 1rem;
}

.error {
  border: red solid 2px;
}

.text-error {
  color: red;
  font-size: 12px;
}

.btn-secondary {
  background: transparent;
  border: #0d6efd solid 1px;
  color: #0d6efd;
}
</style>