<template>
  <form class="row" @submit.prevent >
    <div class="col-12">
      <div class="form-outline mb-4">
        <label class="form-label" for="email">Name</label>
        <input
            v-model="form.name"
            id="name"
            class="form-control"
            placeholder="Agent name"
        />
      </div>
    </div>
    <div class="col-12">
      <button class="btn btn-primary" :disabled="btnDisabled" style="width: 100%" @click="changeName">
        <font-awesome-icon icon="fa-save"></font-awesome-icon>
        Save name
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import validation from "@/components/js/Validation";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: {FontAwesomeIcon},
  emits: ["submitted"],
  props: {
    agent: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      form: {
        name: "",
      },
      btnDisabled: false,
    }
  },
  mounted() {

    if (this.agent) {
      this.form.name = this.agent.name
    }
  },
  methods: {
    changeName() {
      this.btnDisabled = true
      axios.put('/api/v1/client/private/agent/' + this.agent.id, this.form).then(r => {
        this.$notify({
          title: "Name success updated!",
          text: "",
          type: 'success',
          duration: 12000,
        });
        this.btnDisabled = false
        this.agent.name = this.form.name
        this.$emit('submitted', this.form.name)
      }).catch(e => {
        this.$notify({
          title: "API error",
          text: e.message,
          type: 'error',
        })
      }).finally(() => {
        this.btnDisabled = false
      })
    }
  },
}
</script>

<style scoped>

</style>