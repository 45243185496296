<template>
  <div class="d-flex justify-content-center" >
    <div class="preloader">
      <BSpinner variant="secondary" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '10px',
    }
  }
}
</script>


<style scoped>
.preloader {
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
    --bs-bg-opacity: 0.5;
    font-weight: bold;
    font-size: 110%;
    padding: 5px;
    margin: 10px;
    border-radius: 3px;
    text-align: center;
}
.preloader .spinner-border {
  color:  #093759 !important;
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
</style>