<template>
  <form class="row" @submit.prevent >
    <div class="col-12">
      <h4 style="color: darkred">This agent will stop working!</h4>
      After the reset, you will need to register agent on the server with the agent using command: <br><pre>sudo wca-tool register</pre>
    </div>
    <div class="col-12">
      <button class="btn btn-primary" :disabled="btnDisabled" style="width: 100%" @click="resetBinding">
        <font-awesome-icon icon="fa-link-slash"></font-awesome-icon>
        Reset binding
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import validation from "@/components/js/Validation";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: {FontAwesomeIcon},
  emits: ["submitted"],
  props: {
    agent: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      form: {
        name: "",
      },
      btnDisabled: false,
    }
  },
  mounted() {
  },
  methods: {
    resetBinding() {
      this.btnDisabled = true
      axios.put('/api/v1/client/private/agent/' + this.agent.id + '/reset-binding').then(r => {
        this.$notify({
          title: "Binding success reset!",
          text: "Now, you can register agent on another IP address",
          type: 'success',
          duration: 12000,
        });
        this.btnDisabled = false
        this.$emit('submitted' )
      }).catch(e => {
        this.$notify({
          title: "API error",
          text: e.message,
          type: 'error',
        })
      }).finally(() => {
        this.btnDisabled = false
      })
    }
  },
}
</script>

<style scoped>

</style>