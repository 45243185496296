<script setup>

import AccountSettingsForm from "@/components/forms/AccountSettingsForm.vue";
import PasswordSettingsForm from "@/components/forms/PasswordSettingsForm.vue";
import EmailSettingsForm from "@/components/forms/EmailSettingsForm.vue";
</script>

<template>
  <div class="pa__container p-3">
    <div class="row">
      <ul class="nav pa__nav pa__nav-tabs">
        <li class="nav-item">
          <a class="nav-link pa__nav-link"
             @click="myFilter('account')"
             :class="{'pa__active': tabAct === 'account'}"
             href="#">Account settings</a>
        </li>
        <li class="nav-item">
          <a class="nav-link pa__nav-link"
             @click="myFilter('password')"
             :class="{'pa__active': tabAct === 'password'}"
             href="#">Change password</a>
        </li>
        <li class="nav-item">
          <a class="nav-link pa__nav-link"
             @click="myFilter('email')"
             :class="{'pa__active': tabAct === 'email'}"
             href="#">Change email</a>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="card-body m-3" v-if="tabAct.toLowerCase() === 'account'">
        <AccountSettingsForm :client-info="clientInfo" @reload-info="reloadInfo"></AccountSettingsForm>
      </div>
      <div class="card-body m-3" v-if="tabAct.toLowerCase() === 'password'">
        <PasswordSettingsForm :client-info="clientInfo" @reload-info="reloadInfo"></PasswordSettingsForm>
      </div>
      <div class="card-body m-3" v-if="tabAct.toLowerCase() === 'email'">
        <EmailSettingsForm :client-info="clientInfo" @reload-info="reloadInfo"></EmailSettingsForm>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  emits: ["reloadInfo"],
  props: {
    clientInfo: {
      type: Object,
      dafault: null,
    },
    reloadInfo: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      tabAct: 'account'
    }
  },
  mounted() {
    this.clientInfo
  },
  methods: {
    myFilter: function (val) {
      this.tabAct = val;
    },
    reloadInfo() {

      this.reloadInfo()
    }
  },
}
</script>

<style scoped>
.pa__nav-link {
  color: #6c757d;
}

.pa__nav-link.pa__active {
  background: transparent;
  border: #6c757d solid 0;
  color: #000000;
}

.pa__nav {
  --bs-nav-link-hover-color: #000000;
}

.pa__nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: 3px;
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
</style>