const ApiEnums = {
    pay_period: {
        MONTH: "Monthly",
        YEAR: "Yearly",
    },
    subscription_status: {
       ACTIVE: "Active",
       EXPIRED: 'Expired',
       DISABLED: 'Disabled',
       UNPAID: 'Unpaid',
       CREATED: "Created",
    },
}

export default class {
    v(type, key) {
        if (typeof ApiEnums[type] !== 'undefined' && typeof ApiEnums[type][key] !== 'undefined' ) {
            return ApiEnums[type][key]
        }
        return key
    }
    list(type) {
        if (typeof ApiEnums[type] !== 'undefined'  ) {
            return ApiEnums[type]
        }
        return null
    }
}