import {createApp} from 'vue'
import App from './App.vue'
import components from '@/components/UI'
import router from "@/router/router";
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import axios from 'axios';
import Notifications from '@kyvg/vue3-notification'
import ApiErrorMessages from "@/components/js/ApiErrorMessages";
import Enums from "@/components/js/enums"
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import LoadScript from "vue-plugin-load-script";
import {  faInfoCircle, faRightFromBracket, faLinkSlash, faSave, faUserSecret, faCheck, faTimes, faEdit, faUserEdit, faPlus, faCopy, faServer} from '@fortawesome/free-solid-svg-icons'
library.add(faInfoCircle, faRightFromBracket, faLinkSlash, faSave, faUserSecret, faCheck, faTimes, faEdit, faUserEdit, faPlus, faCopy, faServer)


axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use(config => {
    let token = localStorage.getItem('AUTH_TOKEN')
    if (token && token !== '') {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});
axios.interceptors.response.use((response) => response, (error) => {
    if(typeof error.response.data !== "undefined" && typeof error.response.data.error !== "undefined") {
        if(error.response.status === 401) {
            localStorage.removeItem('AUTH_TOKEN');
            window.location.reload()
        }
        error.type = error.response.data.error.type
        let typeMessage = ApiErrorMessages(error.response.data.error.type)
        if (typeMessage && typeMessage !== "") {
            error.message = typeMessage
        } else {
            error.message = error.response.data.error.description
        }
    } else {
        error.type = 'UNKNOWN_API_ERROR'
        alert("Unknown server error, try later")
    }
    throw error;
});

const app = createApp(App)

components.forEach(component => {
    app.component(component.name, component)
})


app.config.globalProperties.$enums = new Enums()

app
    .use(LoadScript)
    .use(router)
    .use(Notifications)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(BootstrapVue3)
    .mount('#app')

