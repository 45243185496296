
const apiErrors = {
    OLD_PASSWORD_IS_REQUIRED: "old password is required",
    OLD_PASSWORD_INCORRECT: "old password is incorrect",
    PASSWORD_IS_LOW_SECURITY: "new password is low secure",
    BAD_AUTH: "login or password is incorrect",
    REQUEST_IS_EXPIRED: "link has expired. please, request again",
    SERVER_ERROR: "server error. Please try again later",
    NEW_EMAIL_IS_REQUIRED: "new email is required",
    EMAIL_ALREADY_REGISTERED: "email already registered",
    PASSWORD_IS_REQUIRED: "password is required",
    PASSWORD_INCORRECT: "password is incorrect",
    AGENT_STAT_NOT_FOUND: "agent statistic not found, try later",
    AGENT_HAS_ACTIVE_SUBSCRIPTIONS: "can't delete agent, has active subscriptions",
    TRIAL_NOT_ALLOWED: "trial not allowed",
    CODE_EXPIRED: "Reset password link is expired. Try request reset password again",
}

export default function (type, description = '') {
    if (typeof apiErrors[type] !== 'undefined') {
        return apiErrors[type]
    }
    return description
}